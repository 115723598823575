
import { defineComponent, PropType, reactive } from '@vue/composition-api'

import { dateDashNotationToDot } from '@/utils/convertDate'

import { PersonOutput } from '@/api/types/person'

export default defineComponent({
  name: 'BaseData',
  props: {
    person: {
      type: Object as PropType<PersonOutput>,
      default: null,
    },
  },
  setup: () => {
    return reactive({
      functions: {
        dateDashNotationToDot,
      },
    })
  },
})
