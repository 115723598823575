var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.person ? _c('div', {
    staticClass: "mx-4"
  }, [_c('v-row', {
    staticClass: "mt-3"
  }, [_c('v-col', {
    staticClass: "py-1 d-flex flex-column mt-10"
  }, [_c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.id')) + " ")]), _c('span', [_vm._v(" " + _vm._s(_vm.person.id) + " ")])]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.birthdate')) + " ")]), _vm.person.birthdate ? _c('span', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(_vm.person.birthdate)) + " ")]) : _c('span', [_vm._v("-")])]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.firstContacted')) + " ")]), _vm.person.firstContacted ? _c('span', [_vm._v(" " + _vm._s(_vm.functions.dateDashNotationToDot(_vm.person.firstContacted)) + " ")]) : _c('span', [_vm._v("-")])])]), _c('v-divider', {
    staticClass: "mx-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticClass: "py-1 d-flex flex-column"
  }, [_c('h4', {
    staticClass: "text-subtitle-1 font-weight-bold mb-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.email')) + " ")]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.emailCorporate')) + " ")]), _vm.person.emailCorporate ? _c('span', [_vm._v(" " + _vm._s(_vm.person.emailCorporate) + " ")]) : _c('span', [_vm._v("-")])]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.emailPrivate')) + " ")]), _vm.person.emailPrivate ? _c('span', [_vm._v(" " + _vm._s(_vm.person.emailPrivate) + " ")]) : _c('span', [_vm._v("-")])]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.emailBusiness')) + " ")]), _vm.person.emailBusiness ? _c('span', [_vm._v(" " + _vm._s(_vm.person.emailBusiness) + " ")]) : _c('span', [_vm._v("-")])])]), _c('v-divider', {
    staticClass: "mx-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticClass: "py-1 d-flex flex-column"
  }, [_c('h4', {
    staticClass: "text-subtitle-1 font-weight-bold mb-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.phone')) + " ")]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.phoneCorporate')) + " ")]), _vm.person.phoneCorporate ? _c('span', [_vm._v(" " + _vm._s(_vm.person.phoneCorporate) + " ")]) : _c('span', [_vm._v("-")])]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('persons.profile.tabMenu.baseData.phonePrivate')))]), _vm.person.phonePrivate ? _c('span', [_vm._v(" " + _vm._s(_vm.person.phonePrivate) + " ")]) : _c('span', [_vm._v("-")])]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t('persons.profile.tabMenu.baseData.phoneMobile')))]), _vm.person.phoneMobile ? _c('span', [_vm._v(" " + _vm._s(_vm.person.phoneMobile) + " ")]) : _c('span', [_vm._v("-")])])]), _c('v-divider', {
    staticClass: "mx-2",
    attrs: {
      "vertical": ""
    }
  }), _c('v-col', {
    staticClass: "py-1 d-flex flex-column"
  }, [_c('h4', {
    staticClass: "text-subtitle-1 font-weight-bold mb-3"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.address')) + " ")]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.address1')) + " ")]), _vm.person.address1 ? _c('span', [_vm._v(_vm._s(_vm.person.address1))]) : _c('span', [_vm._v("-")])]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.address2')) + " ")]), _vm.person.address2 ? _c('span', [_vm._v(_vm._s(_vm.person.address2))]) : _c('span', [_vm._v("-")])]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.addressCity')) + " ")]), _vm.person.addressZipCode || _vm.person.addressCity ? _c('span', [_vm._v(" " + _vm._s(_vm.person.addressZipCode) + " " + _vm._s(_vm.person.addressCity) + " ")]) : _c('span', [_vm._v("-")])]), _c('div', {
    staticClass: "pb-4 d-flex flex-column"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('persons.profile.tabMenu.baseData.addressCountry')) + " ")]), _vm.person.addressCountry ? _c('span', [_vm._v(" " + _vm._s(_vm.person.addressCountry) + " ")]) : _c('span', [_vm._v("-")])])])], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }